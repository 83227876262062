<template>
  <div class="app-container">
    <!--工具栏$erp.enable()true:直营门店 flase 经销商门店 -->
    <div class="head-container">
      <el-input v-if="$erp.enable()" v-model="query.erpCode" clearable placeholder="ERP编码搜索" style="width: 150px;"
        class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.value" clearable placeholder="根据名称搜索" style="width: 200px;" class="filter-item"
        @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <!-- v-permission="['SHOPMANAGE_ALL','SHOPMANAGE_ADD']" -->
      <span v-if="checkPermission(['SHOPMANAGE_ALL', 'SHOPMANAGE_ADD'])">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      </span>
      <span v-else>
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add"
          v-if="$erp.enable()">新增</el-button>
      </span>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading"
        @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eForm ref="form" :is-add="isAdd" />
    <!--表格渲染-->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="erpCode" :label="$erp.enable() ? 'ERP编码' : '门店编码'" width="120">
        <template slot-scope="scope">{{ $erp.enable() ? scope.row.erpCode : scope.row.code }}</template>
      </el-table-column>
      <!-- <el-table-column prop="code" label="门店编码" min-width="200" show-overflow-tooltip /> -->
      <el-table-column prop="name" label="门店名称" min-width="200" show-overflow-tooltip />
      <el-table-column prop="dmsOldName" label="dms原名称" min-width="200" show-overflow-tooltip v-if="!$erp.enable()" />
      <el-table-column prop="status" label="状态" width="100" />

      <el-table-column prop="mallLocationType" label="商场位置等级" width="155" />
      <el-table-column prop="locationType" label="门店位置等级" width="155" />
      <el-table-column prop="expandPerson" label="拓展人员" width="100" />
      <el-table-column prop="managePerson" label="运营人员" width="100" />
      <el-table-column label="地址" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.address }}
        </template>
      </el-table-column>

      <el-table-column prop="mall" label="商场" width="100" show-overflow-tooltip />
      <!-- <el-table-column prop="propertyName" label="所属物业" width="100" show-overflow-tooltip /> -->
      <el-table-column prop="openDate" label="开业日期" width="90"
        :formatter="v => { return v.openDate ? new Date(v.openDate).format('yyyy/MM/dd') : '' }" />
      <el-table-column prop="builtArea" label="建筑面积(㎡)" width="100" />
      <el-table-column prop="useArea" label="实用面积(㎡)" width="100" />
      <el-table-column prop="areaName" label="所属区域" width="100" show-overflow-tooltip />
      <!-- <el-table-column prop="address" label="地址" min-width="240" /> -->
      <el-table-column prop="enabled" label="启用" width="70">
        <template slot-scope="scope">
          <span v-if="checkPermission(['SHOPMANAGE_ALL', 'SHOPMANAGE_ADD'])">
            <el-switch v-model="scope.row.enabled" active-color="#13ce66" @change="updateState(scope.row)" />
          </span>
          <span v-else>
            <el-switch v-model="scope.row.enabled" active-color="#13ce66" @change="updateState(scope.row)"
              :disabled="!$erp.enable()" />
          </span>
        </template>
      </el-table-column>
      <el-table-column width="100" align="center" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini"
                @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini" v-if="$erp.enable()">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import { del, edit } from "@/api/shopInformation";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import request from "@/utils/request";
import eForm from "./detailForm";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      downloadLoading: false,
      loading: false,
      areaAry: [], //所在区域数据
      query: {
        erpCode: null,
        value: null,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
    this.load();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shop";
      this.params = {
        page: this.page,
        size: this.size,
        sort: "createAt,desc",
        shopName: this.query.value,
      };
      if (this.query.erpCode) {
        this.params["erpCode"] = this.query.erpCode;
      }
      return true;
    },
    updateState(d) {
      edit(d).then((_) => {
        this.$notify({
          title: "操作成功",
          type: "success",
          duration: 2500,
        });
      });
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm();
    },
    edit(data) {
      // api/shop/getById/{id}  @Jun  api/shopAuth?shopId=xxx
      const p1 = new Promise((resove, reject) => {
        request({
          url: "/api/shop/getById/" + data.id,
          method: "get",
        })
          .then((res) => {
            resove(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });
      const p2 = new Promise((resove, reject) => {
        request({
          url: "/api/shopAuth?shopId=" + data.id,
          method: "get",
        })
          .then((res) => {
            resove(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });
      Promise.all([p1, p2])
        .then((result) => {
          if (result && result.length) {
            this.isAdd = false;
            this.$refs.form.restForm(
              JSON.parse(JSON.stringify(result[0])),
              result[1].content
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
    load() {
      request({
        url: "/api/area",
        method: "get",
      })
        .then((res) => {
          if (res) {
            this.areaAry = res.content;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/download", this.params)
        .then((result) => {
          downloadFile(result, "门店数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>